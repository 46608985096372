var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: {
          title: _vm.title,
          breadcrumbs: _vm.visibleBreadcrumbs,
          "disable-button": ""
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "v-card",
              {
                staticClass: "rounded-card",
                attrs: { flat: "", outlined: "" }
              },
              [
                _c(
                  "v-card-text",
                  { staticClass: "mt-5 mb-3 px-12" },
                  [
                    _c("app-form", {
                      ref: "form",
                      attrs: {
                        fields: _vm.fields,
                        submitButton: _vm.submitButton,
                        disableCancelButton: ""
                      },
                      on: {
                        "date-changed": _vm.dateChanged,
                        submitted: _vm.handleSubmit
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }